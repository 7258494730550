import "./styles.css"
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import StyledIcon from "../../common/StyledIcons";
import {faPaperPlane, faTrashAlt} from "@fortawesome/fontawesome-free-solid"

const Messages = ({clientName, clientMsg, iconSelected}) => {
    const [onlyIcons, setOnlyIcons] = useState(false)

    useEffect(() => {
        const size = window.screen.width
        size < 992 ? setOnlyIcons(true) : setOnlyIcons(false)
    }, [])

    return(
        <div className="mesg-box">
            <div className="info-mesg">
                <h2>{clientName}</h2>
                <p>{clientMsg}</p>
            </div>
            <div className="footer-mesg">
                <p>Via contacto: {iconSelected}</p>
                <div className="footer-buttons">
                    <Link className="button-msg"
                          to={"/whatsapp"}
                          style={{color: "black", backgroundColor: "#fed403"}}>
                        {onlyIcons ? <StyledIcon
                            iconName={faPaperPlane}
                            withoutMargin={true}
                            className={"icon-text-s"}/> : "Contestar"}
                    </Link>
                    <Link className="button-msg"
                          to={"/delete"}
                          style={{color: "white", backgroundColor: "#F34C4C"}}>
                        {onlyIcons ? <StyledIcon
                            iconName={faTrashAlt}
                            withoutMargin={true}
                            className={"icon-text-s"}/> : "Eliminar"}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Messages