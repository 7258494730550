import "./styles.css"

const ImageContainers = ({title, imageUrl}) => {
    return(
        <div className="image-container">
            <h3>{title}</h3>
            <div className="image-box">
                <img loading={"lazy"}
                     src={imageUrl}
                     alt={"Foto"}
                     className={"image"}/>
            </div>
        </div>
    )
}

export default ImageContainers