import "./styles.css"

const CallBox = () => {
    return(
        <div className="callbox-container">
            <div className="callbox-section">
                <h2>2367-5164</h2>
            </div>
        </div>
    )
}

export default CallBox