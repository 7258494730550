import "./styles.css"
import Header from "../../common/Header";

const AboutSection = () => {
    return(
        <div className="about-container">
            <Header colorBlack={true}/>
            <div className="about-section">
                <div className="vision-section">
                    <h1>Nuestra visión</h1>
                    <p>Somos una empresa que quiere llegar a cada espacio donde nuestros
                        clientes quieran sentirse cómodos, ofreciendoles lo mejor en relación calidad-precio
                        y ganarnos la confianza de ellos mismos, que es lo más importante para nosotros
                        para construir una fuerte relación con nuestros clientes.
                    </p>
                </div>
                <div className="title-section">
                    <h1>NOSOTROS</h1>
                </div>
                <div className="mission-section">
                    <h1>Nuestra misión</h1>
                    <p>Llegar a ofrecer los mejores servicios de mantenimiento, fabricación y reparación
                        de mobiliario de oficina, hogar y comercio del mercado. Ser la mejor opción
                        de nuestros clientes a la hora de querer realizar un proyecto o una idea y ser la
                        herramienta de producción y confianza de nuestros clientes.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutSection