import React from "react"
import {Carousel} from "react-bootstrap";

const Carrousel = ({image1, image2, image3, image4, fabOrRep, titulo1, desc1, titulo2, desc2,
                       titulo3, desc3, titulo4, desc4}) => {
    const storageRef = fabOrRep === "reparacion" ? process.env.REACT_APP_STORAGE_REF_REP :
        process.env.REACT_APP_STORAGE_REF_FAB

    return(
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    loading={"lazy"}
                    src={storageRef + image1}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>{titulo1}</h3>
                    <p>{desc1}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    loading={"lazy"}
                    src={storageRef + image2}
                    alt="Este es titulo"
                />
                <Carousel.Caption>
                    <h3>{titulo2}</h3>
                    <p>{desc2}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    loading={"lazy"}
                    src={storageRef + image3}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h3>{titulo3}</h3>
                    <p>{desc3}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    loading={"lazy"}
                    src={storageRef + image4}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h3>{titulo4}</h3>
                    <p>{desc4}</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default Carrousel