import "./styles.css"
import TextDecor from "../../../../components/common/TextDecor";
import CotizarButton from "../../../../components/common/CotizarButton";
import {useEffect, useState} from "react";
import StyledIcon from "../../../../components/common/StyledIcons";
import {faUserFriends, faRulerCombined, faPeopleCarry} from "@fortawesome/fontawesome-free-solid"

const TrabajoSection = () => {
    const [sizeButton, setSizeButton] = useState("")
    const [iconSize, setIconSize] = useState("")
    const [texto1, setTexto1] = useState("")
    const [texto2, setTexto2] = useState("")
    const [texto3, setTexto3] = useState("")

    useEffect(() => {
        const size = window.screen.width
        const changeSizeButton = () => size > 1400 ? setSizeButton("l") :
            size > 992 && size < 1399 ? setSizeButton("md") :
                size < 991 && size > 440  ? setSizeButton("s"): setSizeButton("p")

        const changeIconSize = () => size <= 768 ? setIconSize("icon-text-service") :
            size < 576 ? setIconSize("icon-text-s") : setIconSize("icon-m")

        const changeText = () => {
            if(size > 768){
                setTexto1("Hablamos contigo para que nos cuentes tu idea y así poder asesorarte\n" +
                    "para realizarlo sin problemas.")

                setTexto2("Iniciamos con el diseño y producción de tu idea y te lo presentamos para\n" +
                    "que todo esté como tu quieres.")

                setTexto3("Ya finalizado el proyecto, vamos a entregartelo ó instalartelo donde lo\n" +
                    "necesites.")
            }else{
                setTexto1("Hablamos contigo para que nos cuentes tu idea.")

                setTexto2("Iniciamos con el diseño y producción de tu idea.")

                setTexto3("Vamos a instalartelo donde lo necesites.")
            }
        }

        changeText()
        changeIconSize()
        changeSizeButton()
    }, [])

    return(
        <div className="work-container">
            <div className="work-section">
                <div className="process-section">
                    <div className="process-box">
                        <div className={"process-box-info"}>
                            <StyledIcon iconName={faUserFriends} withoutMargin={true}
                                        className={iconSize} iconColor={"#000"}/>
                            <div className={"process-box-info-text"}>
                                <p>{texto1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="process-box">
                        <div className={"process-box-info"}>
                            <StyledIcon iconName={faRulerCombined} withoutMargin={true}
                                        className={iconSize} iconColor={"#000"}/>
                            <div className={"process-box-info-text"}>
                                <p>{texto2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="process-box">
                        <div className={"process-box-info"}>
                            <StyledIcon iconName={faPeopleCarry} withoutMargin={true}
                                        className={iconSize} iconColor={"#000"}/>
                            <div className={"process-box-info-text"}>
                                <p>{texto3}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="work-titles-box">
                    <h1 className="work-title">Nuestra forma de trabajar</h1>
                    <TextDecor classSelected="subtitle"/>
                    <p className="work-desc">Trabajamos de esta manera para facilitarte el trabajo y
                        poder entregarte un producto de mejor calidad.</p>
                    <CotizarButton size={sizeButton}/>
                </div>
            </div>
        </div>
    )
}

export default TrabajoSection