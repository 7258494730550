import "./styles.css"
import TextDecor from "../../common/TextDecor";
import React from "react";

const ServiceImages = ({title, desc, pull_apart, image, options}) => {
    const storageRefCards = process.env.REACT_APP_STORAGE_REF_CARDS

    return(
        <div className="service-image-container" style={pull_apart ? {marginRight: 170} : {}}>
            <div className="service-image">
                <img
                    style={{height: "100%", width: "100%"}}
                    loading={"lazy"}
                    src={storageRefCards + image}
                    alt="Imagen servicio"
                />
            </div>
            <div className="image-service-desc">
                <h2>{title}</h2>
                <TextDecor classSelected="subtitle"/>
                <p style={{color: "black"}}>{desc}</p>
                <ul>
                    {options.map(x => <li key={x} className={"options"}>- {x}</li>)}
                </ul>
            </div>
        </div>
    )
}

export default ServiceImages