import "./styles.css"
import React, {useEffect, useState} from "react";

const CardsImages = () => {
    const [changeImages, setChangeImages] = useState(false)
    const [image1, setimage1] = useState("")
    const [image2, setimage2] = useState("")
    const storageRefCards = process.env.REACT_APP_STORAGE_REF_CARDS

    useEffect(() => {
        const size = window.screen.width
        const changeNumberOfCards = () => size < 575 ? setChangeImages(false) : setChangeImages(true)
        const changeImage = () => {
            if(size >= 1400){
                setimage1(process.env.REACT_APP_CARD_IMAGE_1)
                setimage2(process.env.REACT_APP_CARD_IMAGE_2)
            }else{
                setimage1(process.env.REACT_APP_CARD_IMAGE_1_1400)
                setimage2(process.env.REACT_APP_CARD_IMAGE_2_1400)
            }
        }

        changeImage()
        changeNumberOfCards()
    }, [])

    return(
        changeImages ?
                <div className="container">
                    <div className="card">
                        <img
                            style={{height: "100%", width: "100%", borderRadius: 5}}
                            loading={"lazy"}
                            src={storageRefCards + image1}
                            alt="Imagen1"
                        />
                    </div>
                    <div className="card">
                        <img
                            style={{height: "100%", width: "100%", borderRadius: 5}}
                            loading={"lazy"}
                            src={storageRefCards + image2}
                            alt="Imagen2"
                        />
                    </div>
                    <div className="card">
                        <img
                            style={{height: "100%", width: "100%", borderRadius: 5}}
                            loading={"lazy"}
                            src={storageRefCards + process.env.REACT_APP_CARD_IMAGE_3}
                            alt="Imagen3"
                        />
                    </div>
                </div>
                :
                <div className="container">
                    <div className="card">
                        <img
                            style={{height: "100%", width: "100%", borderRadius: 5}}
                            loading={"lazy"}
                            src={storageRefCards + process.env.REACT_APP_CARD_IMAGE_1_1400}
                            alt="Imagen1"
                        />
                    </div>
                    <div className="card">
                        <img
                            style={{height: "100%", width: "100%", borderRadius: 5}}
                            loading={"lazy"}
                            src={storageRefCards + process.env.REACT_APP_CARD_IMAGE_3}
                            alt="Imagen2"
                        />
                    </div>
                </div>
    )
}

export default CardsImages