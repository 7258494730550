import "./styles.css"
import TextDecor from "../../../../components/common/TextDecor";
import Carrousel from "../../../../components/common/Carrousel";

const ProjectsSection = () => {
    return(
        <div className="projects-container">
            <div className="projects-section">
                <div className="projects-title">
                    <h1 className="title-project">Proyectos de fabricación</h1>
                    <TextDecor classSelected="subtitle"/>
                    <p className="desc-project">Sabemos que ver nuestro trabajo es importante,
                        por eso, te dejamos algunos de los proyectos que hemos realizado:
                    </p>
                </div>
                <div className="carousel-section">
                    <Carrousel
                        fabOrRep={"fabricacion"}
                        image1={process.env.REACT_APP_IMAGE_FAB_CAROUSEL_1}
                        image2={process.env.REACT_APP_IMAGE_FAB_CAROUSEL_2}
                        image3={process.env.REACT_APP_IMAGE_FAB_CAROUSEL_3}
                        image4={process.env.REACT_APP_IMAGE_FAB_CAROUSEL_4}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProjectsSection