import "./styles.css"
import ImageContainers from "../../../../components/common/ImageContainers";
import TextDecor from "../../../../components/common/TextDecor";
import CotizarButton from "../../../../components/common/CotizarButton";
import {useEffect, useState} from "react";

const IntroSection = () => {
    const [showImages, setShowImages] = useState(true)
    const [sizeButton, setSizeButton] = useState("")
    const [showDecor, setShowDecor] = useState(true)
    const [selectRepMuebles, setSelectRepMuebles] = useState("")
    const [selectRepSillas, setSelectRepSillas] = useState("")
    const [selectTapiceria, setSelectTapiceria] = useState("")
    const storageRef = process.env.REACT_APP_STORAGE_REF_REP

    useEffect(() => {
        const size = window.screen.width

        const changeButtonSize = () => size > 1400 ? setSizeButton("l") :
            size >= 992 && size < 1399 ? setSizeButton("md"):
                size <= 991 && size > 440  ? setSizeButton("s"): setSizeButton("p")

        const showImagesInPage = () => size < 330 ? setShowImages(false) : setShowImages(true)

        const showDecorationText = () =>  size < 768 && size > 440 ? setShowDecor(false) :
            setShowDecor(true)

        const selectImages = (screenSize) => {
            if (screenSize >= 1400){
                setSelectRepMuebles( storageRef + process.env.REACT_APP_IMAGE_REP_REPMUEBLES_1400)
                setSelectRepSillas(storageRef + process.env.REACT_APP_IMAGE_REP_REPMSILLAS_1400)
                setSelectTapiceria(storageRef + process.env.REACT_APP_IMAGE_REP_TAPICERIA_1400)
            }else if(screenSize >= 992 && screenSize <= 1399){
                setSelectRepMuebles( storageRef + process.env.REACT_APP_IMAGE_REP_REPMUEBLES_1200)
                setSelectRepSillas(storageRef + process.env.REACT_APP_IMAGE_REP_REPMSILLAS_1200)
                setSelectTapiceria(storageRef + process.env.REACT_APP_IMAGE_REP_TAPICERIA_1200)
            }else if(screenSize >= 768 && screenSize <= 991){
                setSelectRepMuebles( storageRef + process.env.REACT_APP_IMAGE_REP_REPMUEBLES_768)
                setSelectRepSillas(storageRef + process.env.REACT_APP_IMAGE_REP_REPMSILLAS_768)
                setSelectTapiceria(storageRef + process.env.REACT_APP_IMAGE_REP_TAPICERIA_768)
            }else if(screenSize >= 576 && screenSize <= 767){
                setSelectRepMuebles( storageRef + process.env.REACT_APP_IMAGE_REP_REPMUEBLES_576)
                setSelectRepSillas(storageRef + process.env.REACT_APP_IMAGE_REP_REPMSILLAS_576)
                setSelectTapiceria(storageRef + process.env.REACT_APP_IMAGE_REP_TAPICERIA_576)
            }else{
                setSelectRepMuebles( storageRef + process.env.REACT_APP_IMAGE_REP_REPMUEBLES_330)
                setSelectRepSillas(storageRef + process.env.REACT_APP_IMAGE_REP_REPMSILLAS_330)
                setSelectTapiceria(storageRef + process.env.REACT_APP_IMAGE_REP_TAPICERIA_330)
            }
        }

        selectImages(size)
        changeButtonSize()
        showImagesInPage()
        showDecorationText()
    }, [storageRef])

    return(
        <div className="rep-container">
            <div className="rep-section">
                <div className="intro-rep-section">
                    <h1 style={{display: "none"}}>Reparación de sillas y mobiliario</h1>
                    {showImages ?
                        <div className="images-intro">
                            <ImageContainers title="Tapicería" imageUrl={selectTapiceria}/>
                            <ImageContainers title="Reparación de sillas" imageUrl={selectRepSillas}/>
                            <ImageContainers title="Reparación de muebles" imageUrl={selectRepMuebles}/>
                        </div>
                        :
                        <div/>
                    }
                    <h1 className="title-rep">Necesitas renovar tu mobiliario</h1>
                    {showDecor ? <TextDecor classSelected="first-title"/> : <div/>}
                    <h4 className="subtitle-rep">Nosotros lo arreglamos y lo dejamos como nuevo.</h4>
                    <CotizarButton size={sizeButton}/>
                </div>
            </div>
        </div>
    )
}

export default IntroSection