import "./style.css"
import {Link} from "react-router-dom";
import StyledIcon from "../StyledIcons";
import {faBars, faTimesCircle} from "@fortawesome/fontawesome-free-solid"
import {useEffect, useState} from "react";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";

const Header = ({withBackground, colorBlack}) => {
    const [showHamburguer, setShowHamburguer] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        const size = window.screen.width
        size < 768 ? setShowHamburguer(true): setShowHamburguer(false)
    }, [])

    const openMenu = () => {
        setShowMenu(true)
    }

    const closeMenu = () => {
        setShowMenu(false)
    }

    return (
        <div>
            {showMenu ?
                <div className={"complete-menu"}>
                    <div className={"complete-menu-x"}>
                        <StyledIcon className={"icon-text-s"}
                                    onClick={() => closeMenu()}
                                    withoutMargin={true}
                                    iconColor={"#fff"}
                                    iconName={faTimesCircle}/>
                    </div>
                    <div className={"complete-menu-options"}>
                        <ul className={"link-list-options"}>
                            <li>
                                <Link to="/" onClick={() => closeMenu()} className={"links-options"}>Principal</Link>
                            </li>
                            <li>
                                <Link to="/contacto" onClick={() => closeMenu()} className={"links-options"}>Contacto</Link>
                            </li>
                            <li>
                                <Link to="/ubicacion" className={"links-options"}>Ubicación</Link>
                            </li>
                            <li>
                                <Link to="/nosotros" className={"links-options"}>Nosotros</Link>
                            </li>
                        </ul>
                        <div className={"icons-menu"}>
                            <a href={"https://www.facebook.com/reparaciondesillasgt"}>
                                <StyledIcon className={"icon-text-l"}
                                            withoutMargin={true}
                                            iconColor={"#fff"}
                                            iconName={faFacebook}/>
                            </a>
                        </div>
                    </div>
                </div> :
                <div/>
            }
            <div className="header-container"
                 style={withBackground && colorBlack?
                     {position: "relative", backgroundColor: "black", color: "white"}
                     :
                     withBackground && !colorBlack ?
                         {position: "relative", color: "black", backgroundColor: "#FAFAFA"}
                         :
                         colorBlack?
                             {position: "absolute", color: "black"}:
                             {position: "absolute", color: "white"}}
            >
                <div className="header-section">
                    <div className="title-header">
                        <Link className="title-header-link"
                              style={withBackground && colorBlack ? {color: "white"}
                                  : withBackground && !colorBlack ? {color: "black"}
                                      : colorBlack? {color: "black"}: {color: "white"}}
                              to={"/"}>Big Office</Link>
                    </div>
                    <div className="links-header">
                        {showHamburguer ?
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <StyledIcon
                                    className={"icon-text-s"}
                                    iconColor={withBackground && colorBlack ? "#fff"
                                        : withBackground && !colorBlack ? "#000"
                                            : colorBlack? "#000": "#fff"}
                                    onClick={() => openMenu()}
                                    iconName={faBars}
                                    withoutMargin={true}/>
                            </div>:<div/>
                        }
                        <ul className="link-list">
                            <li>
                                <Link to="/contacto"
                                      className={withBackground && colorBlack ? "link"
                                          : withBackground && !colorBlack ? "link-white" :
                                              colorBlack ? "link-absolute-color-black"
                                                  : "link-white-color-black"}>Contacto</Link>
                            </li>
                            <li>
                                <Link to="/ubicacion"
                                      className={withBackground && colorBlack ? "link"
                                          : withBackground && !colorBlack ? "link-white" :
                                              colorBlack ? "link-absolute-color-black"
                                                  : "link-white-color-black"}>Ubicación</Link>
                            </li>
                            <li>
                                <Link to="/nosotros"
                                      className={withBackground && colorBlack ? "link"
                                          : withBackground && !colorBlack ? "link-white" :
                                              colorBlack ? "link-absolute-color-black"
                                                  : "link-white-color-black"}>Nosotros</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header