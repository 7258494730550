import "./styles.css"
import TextDecor from "../../../../components/common/TextDecor";
import Carrousel from "../../../../components/common/Carrousel";

const ProjectsRepSection = () => {
    return(
        <div className="projects-rep-container">
            <div className="projects-rep-section">
                <div className="projects-rep-title">
                    <h1>Proyectos tapicería y muebles a medida</h1>
                    <TextDecor classSelected="subtitle"/>
                    <p>Sabemos que ver nuestro trabajo es importante,
                        por eso, te dejamos algunos de los proyectos que hemos realizado:</p>
                </div>
                <div className="rep-images-gallery">
                    <Carrousel
                        image1={process.env.REACT_APP_IMAGE_CAROUSEL_1}
                        image2={process.env.REACT_APP_IMAGE_CAROUSEL_2}
                        image3={process.env.REACT_APP_IMAGE_CAROUSEL_3}
                        image4={process.env.REACT_APP_IMAGE_CAROUSEL_4}
                        fabOrRep={"reparacion"}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProjectsRepSection