import "./styles.css"
import TextDecor from "../../../../components/common/TextDecor";
import CotizarButton from "../../../../components/common/CotizarButton";
import ServiceImages from "../../../../components/specific/ServiceImages";
import {useEffect, useState} from "react";

const ServicesRepSection = () => {
    const [sizeButton, setSizeButton] = useState("")
    const [pullApart, setPullApart] = useState(false)
    const [service1, setService1] = useState("")
    const [service2, setService2] = useState("")

    useEffect(() => {
        const size = window.screen.width
        const changeSizeButton = () => size > 1400 ? setSizeButton("l") :
            size > 992 && size < 1399 ? setSizeButton("md") :
                size < 991 && size > 440  ? setSizeButton("s"): setSizeButton("p")

        const changeServiceImages = () => size >= 2000 ? setPullApart(true) : setPullApart(false)

        const setServiceImages = () => {
            if(size >= 2000){
                setService1(process.env.REACT_APP_SERVICIO_1_2000)
                setService2(process.env.REACT_APP_SERVICIO_2_2000)
            }else if(size < 2000 && size >= 1200){
                setService1(process.env.REACT_APP_SERVICIO_1_1200)
                setService2(process.env.REACT_APP_SERVICIO_2_1200)
            }else if (size < 1200){
                setService1(process.env.REACT_APP_SERVICIO_1_768)
                setService2(process.env.REACT_APP_SERVICIO_2_768)
            }
        }

        setServiceImages()
        changeSizeButton()
        changeServiceImages()
    }, [])

    return(
        <div className="services-rep-container">
            <div className="services-rep-section">
                <div className="title-services">
                    <h1>Nuestros servicios</h1>
                    <TextDecor classSelected="subtitle"/>
                    <p>Tenemos nuestros servicios a tu alcance, hacemos lo imposible para satisfacer
                        tus necesidades y creenos, lo haremos. </p>
                    <CotizarButton size={sizeButton}/>
                </div>
                <div className="services-desc">
                    <ServiceImages
                        pull_apart={pullApart}
                        title="Mobiliario"
                        image={service2}
                        options={["Tapicería", "Muebles a la medida", "Pintura de muebles",
                            "Carpintería", "Pigmentación de cuero"]}
                        desc={
                        "Algunos de nuestros servicios incluyen, lo que es:"
                    }/>
                    <ServiceImages
                        title="Reparación de sillas"
                        pull_apart={pullApart}
                        image={service1}
                        options={["Tapicería", "Sillas de oficina", "Sillas de hogar",
                            "Sillas comerciales", "Mantenimiento"]}
                        desc={
                        "Nuestro servicios sobre sillas incluyen lo que es:"
                    }/>
                </div>
            </div>
        </div>
    )
}

export default ServicesRepSection